<template>
  <div class="video-page">
    <!-- 视频播放 -->
    <video
      id="videoPlayer"
      ref="videoPlayer"
      class="video-player"
      :poster="videoInfo.cover"
      :src="videoUrl"
      :controls="false"
      :autoplay="true"
      :loop="true"
      :show-fullscreen-btn="false"
      :show-center-play-btn="true"
      @click.stop="handleChangeVideoStatus"
    >
      <div class="info-box" @click="handleChangeVideoStatus"></div>
    </video>
    <img
      src="@/static/img/play.png"
      mode="aspectFill"
      class="play-img"
      @click="handleChangeVideoStatus"
      v-if="!videoStatus"
    />
  </div>
</template>

<script>
import { queryVideoDetail } from '../../services/video.js'
export default {
  data () {
    return {
      videoId: '1446843782702624770',
      videoStatus: 1, // 播放状态 0暂停 1播放
      videoInfo: {},
      videoUrl: ''
    }
  },
  methods: {
    // 获取视频详情
    async getVideoDetail () {
      const resp = await queryVideoDetail({
        id: this.videoId
      })
      if (!resp.success) return
      this.videoInfo = resp.result
      // 获取视频地址
      this.videoUrl = resp.result.url
    },
    // 更改播放状态
    handleChangeVideoStatus () {
      if (this.videoStatus) {
        this.$refs.videoPlayer.pause()
        this.videoStatus = !this.videoStatus
      } else {
        this.$refs.videoPlayer.play()
        this.videoStatus = !this.videoStatus
      }
    },
    handleError (err) {
      console.log(err)
    }
  },
  mounted () {
    this.videoId = this.$route.query.id ? this.$route.query.id : ''
    if (this.videoId) {
      this.getVideoDetail()
    }
  },
  // onLoad (option) {
  //   this.videoId = option.id || this.videoId
  // },
  // async onShow () {
  //   this.videoStatus = 1
  //   this.getVideoDetail()
  // },
  computed: {}
  // onReady () {
  //   this.videoContext = uni.createVideoContext('videoPlayer')
  // }
}
</script>

<style lang="scss" scoped>
.video-page {
  .play-img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -29px 0 0 -26px;
    width: 53px;
    height: 58px;
  }

  .video-player {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .info-box {
      color: #ffffff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 80upx;
      box-sizing: border-box;
      padding: 0 30upx;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  button::after {
    border: none;
  }
}
</style>
